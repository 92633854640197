import React, { useState } from "react";
import { graphql } from "gatsby";
import { blockContentToPlainText } from "react-portable-text";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { BundleHeader } from "@components/bundles/bundleHeader";

const BundleProductTemplate = ({ data: { page } }) => {
  // set our Product state
  const { productModules, referencedModules, seo, ...productProps } = page;

  const [bundle] = useState({ ...productProps });

  return (
    <Layout isProduct>
      <PageMeta
        {...seo}
        metaFallback={{
          metaTitle: page.title,
          metaDesc:
            page.description && blockContentToPlainText(page.description),
          shareGraphic: page.bottleShot,
        }}
      />
      <BundleHeader bundle={bundle} />
      {productModules && <ModuleZone {...productModules} />}
      {referencedModules && !productModules && (
        <ModuleZone {...referencedModules.modules} />
      )}
    </Layout>
  );
};

export default BundleProductTemplate;

export const pageQuery = graphql`
  query bundleProductQuery($slug: String!) {
    page: sanityBundleProduct(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      price
      comparePrice
      hasBrand
      productDetail
      shopifyCoupon
      brand {
        ...PageLink
      }
      bgColor {
        hex
      }
      galleryPhotos {
        ...ImageWithPreview
        alt
      }
      bottleShot {
        asset {
          url
        }
      }
      products {
        quantity
        product {
          variants {
            id: variantID
            inStock
            lowStock
            sku
            variantID
          }
        }
      }
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      referencedModules: modules {
        modules {
          ...PageModules
        }
      }
      productModules: specialModules {
        ...PageModules
      }
    }
  }
`;
