import React, { useState } from 'react';

import { GalleryCarousel } from '@components/product/productGallery';
import { ProductDetails } from '@components/product/productDetails';
import { ShippingEstimate } from '@components/product/shippingEstimate';
import { BundleActions } from './bundleActions';
import { MobileBundleAdd } from './mobileBundleAdd';

export const BundleHeader = ({ bundle }) => {
  const [quantity, setQuantity] = useState(1);
  const { products } = bundle || {};
  const inStock =
    products.filter(({ product }) => product.variants[0].inStock).length === products.length;
  return (
    <>
      <div
        className="pt-header-spacer pb-24 grid grid-cols-14 gap-y-4 xl:pb-40"
        style={{ backgroundColor: bundle.bgColor && bundle.bgColor.hex }}
      >
        {/* Image Gallery */}
        <div className="col-start-2 col-end-14 lg:col-end-8">
          <div className="relative">
            <GalleryCarousel photos={bundle.galleryPhotos} />
          </div>
        </div>
        <div className="col-start-2 col-end-14 flex flex-col items-center justify-center gap-y-4 lg:col-start-8 lg:col-end-13 xl:col-start-9">
          {/* Product Details */}
          <ProductDetails product={bundle} />
          {/* Bundle Actions */}
          <BundleActions
            bundle={bundle}
            bundleQuantity={quantity}
            setQuantity={setQuantity}
            inStock={inStock}
          />
          {/* Shipping Estimate */}
          <ShippingEstimate />
        </div>
      </div>
      <MobileBundleAdd
        bundle={bundle}
        bundleQuantity={quantity}
        setQuantity={setQuantity}
        inStock={inStock}
      />
    </>
  );
};
