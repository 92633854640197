import React from 'react';
import cx from 'classnames';
import { useAddItemsToCart, useStore } from '@lib/context';
import { centsToPrice } from '@lib/helpers';
import { ProductPrice } from '@components/product/productPrice';
import { ProductCounter } from '@components/product/productCounter';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export const BundleActions = ({
  bundle,
  setQuantity,
  bundleQuantity,
  inStock,
}) => {
  const { price, comparePrice, products, shopifyCoupon } = bundle || {};

  const addTocart = useAddItemsToCart();

  const { isAdding } = useStore();

  const addItems = () => {
    const items = products.map(({ product, quantity }) => {
      return {
        id: product.variants[0].id,
        qty: quantity * bundleQuantity,
      };
    });
    addTocart(items, shopifyCoupon);
  };

  return (
    <div className={cx({})}>
      <div className='text-xl font-medium py-3 text-center md:text-2xl'>
        <ProductPrice price={price} comparePrice={comparePrice} />
      </div>
      {inStock ? (
        <>
          <ProductCounter
            max={10}
            onUpdate={setQuantity}
            className='border-b-2 border-black mb-6'
          />
          <div
            className={cx('btn w-full text-center justify-center', {
              'is-adding': isAdding,
            })}
            onClick={addItems}
          >
            {isAdding ? 'Adding' : 'Add to bag'}
          </div>
        </>
      ) : (
        <div className='btn cursor-not-allowed hidden md:block'>
          Out of stock
        </div>
      )}
    </div>
  );
};
