import React from "react";
import cx from "classnames";
import { useAddItemsToCart, useStore } from "@lib/context";
import { ProductPrice } from "@components/product/productPrice";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export const MobileBundleAdd = ({ bundle, bundleQuantity, inStock }) => {
  const { price, comparePrice, products, shopifyCoupon } = bundle || {};

  const addTocart = useAddItemsToCart();

  const { isAdding } = useStore();

  const addItems = () => {
    const items = products.map(({ product, quantity }) => {
      return {
        id: product.variants[0].id,
        qty: quantity * bundleQuantity,
      };
    });
    addTocart(items, shopifyCoupon);
  };
  return (
    <div className="fixed bottom-6 left-0 w-full px-nav-gutter z-20 md:hidden">
      {inStock ? (
        <>
          <div
            className={cx(
              "btn-solid large w-full text-center justify-center gap-x-2",
              {
                "is-adding": isAdding,
              }
            )}
            onClick={addItems}
          >
            <ProductPrice price={price} comparePrice={comparePrice} />
            <span>-</span>
            <span className="flex gap-x-2">
              {isAdding ? "Adding" : "Add to bag"}
            </span>
          </div>
        </>
      ) : (
        <div className="btn-solid large w-full text-center justify-center gap-x-2">
          <ProductPrice price={price} comparePrice={comparePrice} /> Out of
          stock
        </div>
      )}
    </div>
  );
};
